import { Box, Text } from "@chakra-ui/react";

export const Th = (props) => {
  return (
    <Text
      as="th"
      textTransform="uppercase"
      fontSize="xs"
      color="gray.500"
      fontWeight="medium"
      px={4}
      bg={"gray.100"}
      whiteSpace="nowrap"
      textAlign="left"
      {...props}
    />
  );
};

export const Td = (props) => {
  return (
    <Box
      as="td"
      p={4}
      borderBottom="1px solid"
      borderBottomColor={"gray.100"}
      height="40px"
      color={"black"}
      {...props}
    />
  );
};

export const Tr = (props) => {
  return (
    <Box
      as="tr"
      backgroundColor="gray.50"
      borderTopLeftRadius={8}
      borderTopRightRadius={8}
      borderBottom="1px solid"
      borderRight="1px solid"
      borderLeft="1px solid"
      borderTop="1px solid"
      borderTopColor={"gray.200"}
      borderBottomColor={"gray.200"}
      borderRightColor={"gray.200"}
      borderLeftColor={"gray.200"}
      height="40px"
      {...props}
    />
  );
};

export const Table = (props) => {
  return (
    <Box
      as="table"
      textAlign="left"
      backgroundColor="white"
      bg={"white"}
      ml={0}
      mr={0}
      borderRadius={8}
      boxShadow="0px 4px 10px rgba(0, 0, 0, 0.05)"
      {...props}
    />
  );
};
