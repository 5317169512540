import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Spinner,
  Stack,
  Button,
  Image,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import sofrecom from "../img/sofrecom.png";
import { useAuth } from "../providers/AuthProvider";

export default function Login() {
  const [data, setData] = useState({
    email: "",
    password: "",
  });
  const { loginEmail, isLoadingEmail, error, user } = useAuth();
  const history = useHistory();
  const toast = useToast();

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    loginEmail(data, history);
  };

  useEffect(() => {
    if (error) {
      toast({
        description:
          typeof error === "string"
            ? error
            : "Tout les champs sont obilgatoires.",
        status: "error",
        duration: 4000,
        isClosable: true,
        variant: "subtle",
      });
    }
    // eslint-disable-next-line
  }, [error]);
  useEffect(() => {
    if (user) {
      history.push("/dashboard");
    }
    // eslint-disable-next-line
  }, [user]);
  return (
    <Flex
      minH={"100vh"}
      justify={"center"}
      bg={useColorModeValue("gray.50", "gray.800")}
    >
      <Stack
        spacing={8}
        mx={"auto"}
        minW={{ base: "100%", md: "lg" }}
        py={12}
        px={6}
      >
        <Stack align={"center"} my="4">
          <Image src={sofrecom} />
        </Stack>
        <Box
          rounded={"lg"}
          bg={useColorModeValue("white", "gray.700")}
          boxShadow={"lg"}
          p={8}
        >
          <Stack spacing={4} as="form" onSubmit={handleSubmit}>
            <FormControl id="email">
              <FormLabel>Adresse Email</FormLabel>
              <Input
                type="email"
                borderRadius="3xl"
                onChange={handleChange}
                name="email"
              />
            </FormControl>
            <FormControl id="password">
              <FormLabel>Mot de passe</FormLabel>
              <Input
                type="password"
                borderRadius="3xl"
                onChange={handleChange}
                name="password"
              />
            </FormControl>
            <Stack spacing={10}>
              <Button
                mt="2"
                type="submit"
                bg={"orange.light"}
                color={"white"}
                _hover={{
                  bg: "orange.dark",
                }}
                _active={{
                  bg: "orange.dark",
                }}
              >
                {isLoadingEmail ? <Spinner /> : "Se connecter"}
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
}
