import {
  Box,
  Center,
  Text,
  Stack,
  Button,
  useColorModeValue,
  Spinner,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axiosInstance from "../config/axiosInstance";

export default function StatsCard({ stats }) {
  const [users, setUsers] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const getNewUsers = async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance({
        url: "/admin/clients/perday?page=1",
        method: "get",
        headers: {
          "auth-token": localStorage.getItem("token"),
        },
      });
      let todayUsers = response.data.dates.find(
        (el) => new Date(el._id).toDateString() == new Date().toDateString()
      );
      if (todayUsers) {
        setUsers(todayUsers.count);
      } else {
        setUsers(0);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setError(error.response.data.msg);
      setIsLoading(false);
    }
  };

  const getUsers = async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance({
        url: "/admin/clients?page=1",
        method: "get",
        headers: {
          "auth-token": localStorage.getItem("token"),
        },
      });
      setUsers(response.data.total);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setError(error.response.data.msg);
      setIsLoading(false);
    }
  };

  const getUsersPerDay = async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance({
        url: "/admin/clients/logged?page=1",
        method: "get",
        headers: {
          "auth-token": localStorage.getItem("token"),
        },
      });
      let todayUsers = response.data.dates.find(
        (el) => new Date(el._id).toDateString() == new Date().toDateString()
      );
      if (todayUsers) {
        setUsers(todayUsers.count);
      } else {
        setUsers(0);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setError(error.response.data.msg);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (stats === "nbrnew") {
      getNewUsers();
    } else if (stats === "nbrtotal") {
      getUsers();
    } else {
      getUsersPerDay();
    }
  }, []);
  return (
    <Center py={6}>
      <Box
        w={"90%"}
        bg={useColorModeValue("white", "gray.800")}
        boxShadow={"2xl"}
        rounded={"lg"}
        overflow={"hidden"}
      >
        <Stack
          textAlign={"center"}
          p={6}
          color={useColorModeValue("gray.800", "white")}
          align={"center"}
        >
          <Text
            fontSize={"sm"}
            fontWeight={500}
            bg={useColorModeValue("blue.50", "green.900")}
            p={2}
            px={3}
            color={"blue.500"}
            rounded={"full"}
          >
            {stats === "nbrnew"
              ? "Nombre de nouveau utilisateur aujourd'hui"
              : stats === "nbrjour"
              ? "Nombre de connexion aujourd'hui"
              : "Nombre d'utilisateur total"}
          </Text>
          <Stack direction={"row"} align={"center"} justify={"center"}>
            {isLoading ? (
              <Spinner size="xs" />
            ) : (
              <Text fontSize={"6xl"} fontWeight={800}>
                {users}
              </Text>
            )}
            <Text color={"gray.500"}>utilisateur{users > 1 ? "s" : ""}</Text>
          </Stack>
        </Stack>

        <Box bg={useColorModeValue("gray.50", "gray.900")} px={6} py={10}>
          <Link
            to={
              stats === "nbrnew"
                ? `/dashboard/utilisateurs/nouveaux`
                : stats === "nbrjour"
                ? "/dashboard/utilisateurs/tous"
                : "/dashboard/utilisateurs"
            }
          >
            <Button
              w={"full"}
              bg={"blue.dark"}
              color={"white"}
              rounded={"md"}
              boxShadow={"0 5px 20px 0px rgb(0 0 80 / 43%)"}
              _hover={{
                bg: "blue.500",
              }}
              _focus={{
                bg: "blue.500",
              }}
            >
              <Link to={stats === "nbrtotal" ? "/dashboard/utilisateurs" : ""}>
                Voir détails
              </Link>
            </Button>
          </Link>
        </Box>
      </Box>
    </Center>
  );
}
