import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  styles: {
    global: {
      body: {
        fontFamily: "Outsiders_Medium, sans-serif",
      },
    },
  },
  colors: {
    black: "#2B3C40",
    orange: {
      light: "#FEC500",
      dark: "#F89B00",
    },
    gray: {
      bg: "#FAFAFA",
      border: "#D9D9D9",
    },
    blue: {
      light: "#F3FAFE",
      dark: "#0E67B0",
    },
    mint: "#2DA8A3",
  },
  components: {
    Heading: {
      baseStyle: {
        fontFamily: "Outsiders_Medium, sans-serif",
      },
    },
    Button: {
      baseStyle: {
        borderRadius: "50px",
        color: "white",
        fontFamily: "Outsiders_Medium, sans-serif",
        fontWeight: "semiBold",
      },
      variants: {
        "white-btn": {
          bg: "whiteAlpha.600",
          color: "black",
          "&:hover": {
            bg: "white",
          },
        },
        "without-gradient": {
          bg: "#FEC500",
          "&:hover": {
            bg: "#F89B00",
          },
        },
        "with-gradient": {
          bg: "rgb(248, 155, 0)",
          textTransform: "uppercase",
          fontWeight: "semiBold",
          bgGradient: "linear(to-r, rgb(248, 155, 0), rgb(255, 197, 0))",
          "&:hover": {
            bg: "#FFC500",
            transform: "scale(1.1)",
          },
        },
      },
    },
  },
});

export default theme;
