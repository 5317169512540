import { Route, Redirect } from "react-router-dom";
import { useAuth } from "../providers/AuthProvider";

const PrivateRoute = ({ path, exact, component: Component }) => {
  const auth = useAuth();

  return auth.isLoggedIn && auth.user && auth.user.role === "admin" ? (
    <Route path={path} exact={exact} component={Component} />
  ) : (
    <Redirect to="/" />
  );
};

export default PrivateRoute;
