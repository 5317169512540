import React from "react";
import {
  IconButton,
  Box,
  CloseButton,
  Flex,
  Icon,
  useColorModeValue,
  Drawer,
  DrawerContent,
  Button,
  useDisclosure,
  Image,
} from "@chakra-ui/react";
import { FiHome, FiUsers, FiMenu } from "react-icons/fi";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import { MdOutlineMoreTime } from "react-icons/md";
import { FaAward } from "react-icons/fa";
import { FiLogOut } from "react-icons/fi";
import { Link, useHistory } from "react-router-dom";
import logo from "../img/sofrecom.png";
import { useAuth } from "../providers/AuthProvider";

const LinkItems = [
  { name: "Home", icon: FiHome, href: "/dashboard" },
  { name: "Utilisateurs", icon: FiUsers, href: "/dashboard/utilisateurs" },
  {
    name: "Nouveaux utilisateurs par jour",
    icon: AiOutlineUsergroupAdd,
    href: "/dashboard/utilisateurs/nouveaux",
  },
  {
    name: "Utilisateurs par jour",
    icon: MdOutlineMoreTime,
    href: "/dashboard/utilisateurs/tous",
  },
  { name: "Les qualifiées", icon: FaAward, href: "/dashboard/qualifiées" },
];

export default function Sidebar({ children }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Box minH="100vh" bg={useColorModeValue("gray.100", "gray.900")}>
      <SidebarContent
        onClose={() => onClose}
        display={{ base: "none", lg: "block" }}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="md"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav display={{ base: "flex", lg: "none" }} onOpen={onOpen} />
      <Box ml={{ base: 0, lg: 80 }} p="4">
        {children}
      </Box>
    </Box>
  );
}

const SidebarContent = ({ onClose, ...rest }) => {
  const { logOut } = useAuth();
  return (
    <Box
      bg={useColorModeValue("white", "gray.900")}
      borderRight="1px"
      zIndex="1"
      borderRightColor={useColorModeValue("gray.200", "gray.700")}
      w={{ base: "full", lg: 80 }}
      pos="fixed"
      h="full"
      {...rest}
    >
      <Flex
        h="20"
        alignItems="center"
        mx="8"
        justifyContent="space-between"
        my="4"
      >
        <Image src={logo} alt="Sofrecom" maxW="100px" />
        <CloseButton display={{ base: "flex", lg: "none" }} onClick={onClose} />
      </Flex>
      {LinkItems.map((link) => (
        <NavItem key={link.name} icon={link.icon} href={link.href}>
          {link.name}
        </NavItem>
      ))}
      <Flex
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          bg: "orange.light",
          color: "white",
        }}
        {...rest}
        transition="all 0.2s ease-in-out"
        my="2"
        onClick={logOut}
      >
        <Icon
          mr="4"
          fontSize="16"
          _groupHover={{
            color: "white",
          }}
          as={FiLogOut}
        />
        Se déconnecter
      </Flex>
    </Box>
  );
};

const NavItem = ({ icon, children, href, ...rest }) => {
  const history = useHistory();
  return (
    <Link to={href} style={{ textDecoration: "none" }}>
      <Flex
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          bg: "orange.light",
          color: "white",
        }}
        {...rest}
        bg={history.location.pathname === href ? "orange.dark" : "white"}
        color={history.location.pathname === href ? "white" : "black"}
        transition="all 0.2s ease-in-out"
        my="2"
      >
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: "white",
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </Link>
  );
};

const MobileNav = ({ onOpen, ...rest }) => {
  return (
    <Flex
      ml={{ base: 0, lg: 60 }}
      px={{ base: 4, lg: 24 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue("white", "gray.900")}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue("gray.200", "gray.700")}
      justifyContent="flex-start"
      {...rest}
    >
      <IconButton
        variant="outline"
        onClick={onOpen}
        aria-label="open menu"
        icon={<FiMenu />}
      />

      <Image src={logo} alt="Sofrecom" width="100%" maxW="100px" ml="6" />
    </Flex>
  );
};
