import "./App.css";
import { Switch, Route } from "react-router-dom";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import PrivateRoute from "./components/PrivateRoute";
import Users from "./pages/Users";
import UsersPerDay from "./pages/UsersPerDay";
import UsersDay from "./pages/UsersDay";
import Qualified from "./pages/Qualified";

function App() {
  return (
    <div>
      <Switch>
        <Route exact path="/" component={Login} />
        <PrivateRoute exact path="/dashboard" component={Dashboard} />
        <PrivateRoute exact path="/dashboard/utilisateurs" component={Users} />
        <PrivateRoute
          exact
          path="/dashboard/utilisateurs/:status"
          component={UsersPerDay}
        />
        <PrivateRoute
          exact
          path="/dashboard/nouveau-utilisateurs/:date"
          component={UsersDay}
        />
        <PrivateRoute
          exact
          path="/dashboard/qualifiées"
          component={Qualified}
        />
      </Switch>
    </div>
  );
}

export default App;
