import { useState, useContext, createContext } from "react";
import axiosInstance from "../config/axiosInstance";

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export function AuthProvider({ children }) {
  const auth = useAuthProvider();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}

export function useAuthProvider() {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingEmail, setIsLoadingEmail] = useState(false);
  const [isLoadingUser, setIsLoadingUser] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [error, setError] = useState(null);

  //   LOAD CONNECTED USER
  const loadConnectedUser = async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance({
        url: "/admin",
        method: "get",
        headers: {
          "auth-token": localStorage.getItem("token"),
        },
      });
      setUser(response.data);
      setIsLoading(false);
      setIsLoggedIn(true);
      setIsLoadingUser(false);
    } catch (error) {
      console.log(error);
      localStorage.removeItem("token");
      setUser(null);
      setIsLoadingUser(false);
      setToken(null);
      setIsLoggedIn(false);
      setIsLoading(false);
      setError(error.response.data.error);
    }
  };

  // LOGIN USER WITH EMAIL
  const loginEmail = async (data, history) => {
    setIsLoadingEmail(true);
    try {
      const response = await axiosInstance({
        url: "/admin/login",
        method: "post",
        data,
      });
      localStorage.setItem("token", response.data.token);
      setUser(response.data.user);
      setToken(response.data.token);
      setIsLoadingEmail(false);
      setIsLoadingUser(false);
      setIsLoggedIn(true);
      history.push("/dashboard");
    } catch (error) {
      localStorage.removeItem("token");
      console.log(error);
      setUser(null);
      setToken(null);
      setIsLoggedIn(false);
      setIsLoadingUser(false);
      setIsLoadingEmail(false);
      setError(
        error.response.data.fields
          ? error.response.data.msg.map((el) => el.param)
          : error.response.data.msg
      );
      //   setError(error);
      setTimeout(() => {
        setError(null);
      }, 10000);
    }
  };

  const logOut = () => {
    localStorage.removeItem("token");
    setUser(null);
    setToken(null);
    setIsLoggedIn(false);
  };

  return {
    user,
    isLoading,
    isLoadingEmail,
    isLoadingUser,
    isLoggedIn,
    error,
    loadConnectedUser,
    loginEmail,
    logOut,
  };
}
