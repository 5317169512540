import React, { useEffect, useState } from "react";
import axiosInstance from "../config/axiosInstance";
import Sidebar from "../layout/Sidebar";
import {
  Table,
  Center,
  Spinner,
  Flex,
  Divider,
  Heading,
  Button,
  IconButton,
} from "@chakra-ui/react";
import { Th, Tr, Td } from "../components/Table";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import moment from "moment";
import { fr } from "moment/locale/fr";

moment.locale(fr);

export default function UsersPerDay() {
  const [users, setUsers] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const { status } = useParams();

  function useQueryy() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQueryy();

  const getDates = async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance({
        url:
          "/admin/clients/perday?page=" +
          (query.get("page") === null ? 1 : query.get("page")),
        method: "get",
        headers: {
          "auth-token": localStorage.getItem("token"),
        },
      });
      setUsers(response.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setError(error.response.data.msg);
      setIsLoading(false);
    }
  };
  const getUsersLoggedIn = async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance({
        url:
          "/admin/clients/logged?page=" +
          (query.get("page") === null ? 1 : query.get("page")),
        method: "get",
        headers: {
          "auth-token": localStorage.getItem("token"),
        },
      });
      setUsers(response.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setError(error.response.data.msg);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (status === "nouveaux") {
      getDates();
    } else if (status === "tous") {
      getUsersLoggedIn();
    }
  }, [query.get("page"), status]);

  return (
    <Sidebar>
      <Flex align="center">
        <Divider width="5%" minW="50px" borderColor="gray.300" />
        <Heading py="2" px="4" whiteSpace="nowrap" size="lg">
          {status === "nouveaux"
            ? "Nouveaux utilisateurs par jour"
            : "Utilisateurs par jour"}
        </Heading>
        <Divider width="full" borderColor="gray.300" />
      </Flex>
      <Table my="4" rounded="md" overflow="hidden">
        <thead>
          <Tr background="red">
            <Th background="gray.200" color="black" fontWeight="bold">
              Date
            </Th>
            <Th background="gray.200" color="black" fontWeight="bold">
              {status !== "nouveaux"
                ? "Nombre de connexion"
                : "Nombre d'utilisateurs"}
            </Th>
            <Th background="gray.200" color="black" fontWeight="bold">
              Action
            </Th>
          </Tr>
        </thead>
        <tbody>
          {isLoading ? (
            <Tr>
              <td colSpan="6" style={{ padding: "2rem" }}>
                <Center w="100%">
                  <Spinner />
                </Center>
              </td>
            </Tr>
          ) : users && users.dates.length === 0 ? (
            <Tr>
              <Td colSpan="10" textAlign="center">
                Pas de réponse
              </Td>
            </Tr>
          ) : (
            users &&
            users.dates.map((user) => (
              <Tr key={user._id}>
                <Td>{moment(user._id).format("dddd, DD MMMM YYYY")}</Td>
                <Td>{user.count}</Td>
                <Td>
                  <Button rounded="md" colorScheme="facebook" variant="ghost">
                    <Link to={`/dashboard/nouveau-utilisateurs/${user._id}`}>
                      Voir
                    </Link>
                  </Button>
                </Td>
              </Tr>
            ))
          )}
        </tbody>
        <tfoot>
          <Tr rounded="md">
            <Td>Total: {users?.total} </Td>
            <Td colSpan="6" align="center" p="2">
              {users?.num_pages === 0 ? null : (
                <Flex justify="flex-end" borderRadius="5" p="1">
                  <IconButton
                    icon={<ChevronLeftIcon />}
                    fontSize="large"
                    bg="blue.500"
                    _hover={{
                      bg: "blue.400",
                    }}
                    onClick={() =>
                      users.previous &&
                      history.push(
                        `/dashboard/utilisateurs/${status}?page=${users.previous.page}`
                      )
                    }
                  />
                  {Array.apply(null, Array(users?.num_pages)).map((_, i) => (
                    <Button
                      variant="outline"
                      onClick={() =>
                        history.push(
                          `/dashboard/utilisateurs/${status}?page=${i + 1}`
                        )
                      }
                      mx="1"
                      key={i}
                      bg={
                        !query.get("page") && i === 0
                          ? "orange.light"
                          : query.get("page") == i + 1
                          ? "orange.light"
                          : "inherit"
                      }
                      color={
                        !query.get("page") && i === 0
                          ? "white"
                          : query.get("page") == i + 1
                          ? "white"
                          : "inherit"
                      }
                    >
                      {i + 1}
                    </Button>
                  ))}
                  <IconButton
                    icon={<ChevronRightIcon />}
                    bg="blue.500"
                    _hover={{
                      bg: "blue.400",
                    }}
                    fontSize="large"
                    onClick={() =>
                      users.next &&
                      history.push(
                        `/dashboard/utilisateurs/${status}?page=${users.next.page}`
                      )
                    }
                  />
                </Flex>
              )}
            </Td>
          </Tr>
        </tfoot>
      </Table>
    </Sidebar>
  );
}
