import { SimpleGrid, Grid } from "@chakra-ui/react";
import RecentUsers from "../components/RecentUsers";
import StatsCard from "../components/StatsCard";
import Sidebar from "../layout/Sidebar";

export default function Dashboard() {
  return (
    <Sidebar>
      <Grid templateColumns={{ base: "1fr", md: "repeat(3,0.5fr)" }} gap="10">
        {/* // columns={{ base: 1, md: 3 }} spacing={10}> */}
        <StatsCard stats={"nbrtotal"} />
        <StatsCard stats={"nbrjour"} />
        <StatsCard stats={"nbrnew"} />
      </Grid>
      <RecentUsers />
    </Sidebar>
  );
}
