import React, { useEffect, useState } from "react";
import axiosInstance from "../config/axiosInstance";
import {
  Box,
  Table,
  Center,
  Spinner,
  Heading,
  Flex,
  Divider,
  Button,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { Th, Tr, Td } from "../components/Table";
import { BsFacebook } from "react-icons/bs";
import { MdOutlineMailOutline } from "react-icons/md";

export default function RecentUsers() {
  const [users, setUsers] = useState(null);
  const [error, setError] = useState(null);

  const getRecentUsers = async () => {
    try {
      const response = await axiosInstance({
        url: "/admin/clients/last",
        method: "get",
        headers: {
          "auth-token": localStorage.getItem("token"),
        },
      });
      setUsers(response.data);
    } catch (error) {
      console.log(error);
      setError(error.response.data.msg);
    }
  };
  useEffect(() => {
    getRecentUsers();
  }, []);
  return (
    <Box mt="6">
      <Flex align="center">
        <Divider width="5%" minW="50px" borderColor="gray.300" />
        <Heading py="2" px="4" whiteSpace="nowrap">
          Inscriptions récentes
        </Heading>
        <Divider width="full" borderColor="gray.300" />
      </Flex>
      <Table my="4" rounded="md" overflow="hidden">
        <thead>
          <Tr background="red">
            <Th background="gray.200" color="black" fontWeight="bold"></Th>
            <Th background="gray.200" color="black" fontWeight="bold">
              Nom et prénom
            </Th>
            <Th background="gray.200" color="black" fontWeight="bold">
              Adresse email
            </Th>
            <Th background="gray.200" color="black" fontWeight="bold">
              Téléphone
            </Th>
            <Th background="gray.200" color="black" fontWeight="bold">
              Profession
            </Th>
            <Th background="gray.200" color="black" fontWeight="bold">
              Date de création
            </Th>
          </Tr>
        </thead>
        <tbody>
          {!users ? (
            <Tr>
              <td colSpan="6" style={{ padding: "2rem" }}>
                <Center w="100%">
                  <Spinner />
                </Center>
              </td>
            </Tr>
          ) : users.length === 0 ? (
            <Tr>
              <Td colSpan="10" textAlign="center">
                Pas de réponse
              </Td>
            </Tr>
          ) : (
            users.map((user) => (
              <Tr key={user._id}>
                <Td>
                  {user.provider === "Facebook" ? (
                    <BsFacebook size="1.5rem" color="#0E67B0" />
                  ) : (
                    <MdOutlineMailOutline size="1.5rem" />
                  )}
                </Td>
                <Td>{user.name}</Td>
                <Td>{user.email}</Td>
                <Td>{user.phone}</Td>
                <Td>{user.status}</Td>
                <Td>{new Date(user.createdAt).toLocaleString()}</Td>
              </Tr>
            ))
          )}
        </tbody>
        <tfoot>
          <Tr rounded="md">
            <Td
              colSpan="6"
              align="center"
              p="2"
              _hover={{ bg: "blue.500", color: "white" }}
            >
              <Button variant="link" color={"inherit"} w="100%" h="full">
                <Link to={"/dashboard/utilisateurs"}>Voir tout</Link>
              </Button>
            </Td>
          </Tr>
        </tfoot>
      </Table>
    </Box>
  );
}
