import Sidebar from "../layout/Sidebar";
import {
  Table,
  Center,
  Spinner,
  IconButton,
  Button,
  Flex,
} from "@chakra-ui/react";
import { Th, Tr, Td } from "../components/Table";
import { useEffect, useState } from "react";
import axiosInstance from "../config/axiosInstance";
import { BsFacebook } from "react-icons/bs";
import { MdOutlineMailOutline } from "react-icons/md";
import { useHistory, useLocation } from "react-router-dom";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";

export default function Users() {
  const [users, setUsers] = useState(null);
  const [error, setError] = useState(null);
  const history = useHistory();

  function useQueryy() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQueryy();

  const getRecentUsers = async () => {
    try {
      const response = await axiosInstance({
        url:
          "/admin/clients?page=" +
          (query.get("page") === null ? 1 : query.get("page")),
        method: "get",
        headers: {
          "auth-token": localStorage.getItem("token"),
        },
      });
      setUsers(response.data);
    } catch (error) {
      console.log(error);
      setError(error.response.data.msg);
    }
  };
  useEffect(() => {
    getRecentUsers();
  }, [query.get("page")]);
  return (
    <Sidebar>
      <Table my="4" rounded="md" overflow="hidden">
        <thead>
          <Tr background="red">
            <Th background="gray.200" color="black" fontWeight="bold"></Th>
            <Th background="gray.200" color="black" fontWeight="bold">
              Nom et prénom
            </Th>
            <Th background="gray.200" color="black" fontWeight="bold">
              Adresse email
            </Th>
            <Th background="gray.200" color="black" fontWeight="bold">
              Téléphone
            </Th>
            <Th background="gray.200" color="black" fontWeight="bold">
              Profession
            </Th>
            <Th background="gray.200" color="black" fontWeight="bold">
              Score
            </Th>
            <Th background="gray.200" color="black" fontWeight="bold">
              Tentatives
            </Th>
            <Th background="gray.200" color="black" fontWeight="bold">
              Date de création
            </Th>
          </Tr>
        </thead>
        <tbody>
          {!users ? (
            <Tr>
              <td colSpan="8" style={{ padding: "2rem" }}>
                <Center w="100%">
                  <Spinner />
                </Center>
              </td>
            </Tr>
          ) : users.users.length === 0 ? (
            <Tr>
              <Td colSpan="10" textAlign="center">
                Pas de réponse
              </Td>
            </Tr>
          ) : (
            users.users.map((user) => (
              <Tr key={user._id}>
                <Td>
                  {user.provider === "Facebook" ? (
                    <BsFacebook size="1.5rem" color="#0E67B0" />
                  ) : (
                    <MdOutlineMailOutline size="1.5rem" />
                  )}
                </Td>
                <Td>{user.name}</Td>
                <Td>{user.email}</Td>
                <Td>{user.phone}</Td>
                <Td>{user.status}</Td>
                <Td>{user.score}</Td>
                <Td>{user.attempts}</Td>
                <Td>{new Date(user.createdAt).toLocaleString()}</Td>
              </Tr>
            ))
          )}
        </tbody>
        <tfoot>
          <Tr rounded="md">
            <Td>
              <Flex>
                <span>Total: </span>
                {users?.total}
              </Flex>
            </Td>
            <Td colSpan="7" align="center" p="2">
              {users?.num_pages === 0 ? null : (
                <Flex justify="flex-end" borderRadius="5" p="1" wrap="wrap">
                  <IconButton
                    icon={<ChevronLeftIcon />}
                    fontSize="large"
                    bg="blue.500"
                    _hover={{
                      bg: "blue.400",
                    }}
                    onClick={() =>
                      users.previous &&
                      history.push(
                        `/dashboard/utilisateurs?page=${users.previous.page}`
                      )
                    }
                  />
                  {Array.apply(null, Array(users?.num_pages)).map((_, i) => (
                    <Button
                      variant="outline"
                      onClick={() =>
                        history.push(`/dashboard/utilisateurs?page=${i + 1}`)
                      }
                      mx="1"
                      key={i}
                      bg={
                        !query.get("page") && i === 0
                          ? "orange.light"
                          : query.get("page") == i + 1
                          ? "orange.light"
                          : "inherit"
                      }
                      color={
                        !query.get("page") && i === 0
                          ? "white"
                          : query.get("page") == i + 1
                          ? "white"
                          : "inherit"
                      }
                    >
                      {i + 1}
                    </Button>
                  ))}
                  <IconButton
                    icon={<ChevronRightIcon />}
                    bg="blue.500"
                    _hover={{
                      bg: "blue.400",
                    }}
                    fontSize="large"
                    onClick={() =>
                      users.next &&
                      history.push(
                        `/dashboard/utilisateurs?page=${users.next.page}`
                      )
                    }
                  />
                </Flex>
              )}
            </Td>
          </Tr>
        </tfoot>
      </Table>
    </Sidebar>
  );
}
