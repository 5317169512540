import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams, Link } from "react-router-dom";
import axiosInstance from "../config/axiosInstance";
import Sidebar from "../layout/Sidebar";
import {
  Flex,
  Table,
  Spinner,
  Heading,
  Divider,
  Center,
  IconButton,
  Button,
} from "@chakra-ui/react";
import { Td, Tr, Th } from "../components/Table";
import moment from "moment";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { BsFacebook } from "react-icons/bs";
import { MdOutlineMailOutline } from "react-icons/md";

export default function UsersDay() {
  const [users, setUsers] = useState(null);
  const [error, setError] = useState(null);
  const history = useHistory();
  const { date } = useParams();

  function useQueryy() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQueryy();

  const getDates = async () => {
    try {
      const response = await axiosInstance({
        url: `/admin/clients/perday/${date}?page=${
          query.get("page") === null ? 1 : query.get("page")
        }`,
        method: "get",
        headers: {
          "auth-token": localStorage.getItem("token"),
        },
      });
      setUsers(response.data);
    } catch (error) {
      console.log(error);
      setError(error.response.data.msg);
    }
  };
  useEffect(() => {
    getDates();
  }, [query.get("page")]);
  return (
    <Sidebar>
      <Flex align="center">
        <Divider width="5%" minW="50px" borderColor="gray.300" />
        <Heading py="2" px="4" whiteSpace="nowrap" size="md">
          Nouveaux utilisateurs le {new Date(date).toLocaleDateString()}
        </Heading>
        <Divider width="full" borderColor="gray.300" />
      </Flex>
      <Table my="4" rounded="md" overflow="hidden">
        <thead>
          <Tr background="red">
            <Th background="gray.200" color="black" fontWeight="bold"></Th>
            <Th background="gray.200" color="black" fontWeight="bold">
              Nom et prénom
            </Th>
            <Th background="gray.200" color="black" fontWeight="bold">
              Adresse email
            </Th>
            <Th background="gray.200" color="black" fontWeight="bold">
              Téléphone
            </Th>
          </Tr>
        </thead>
        <tbody>
          {!users ? (
            <Tr>
              <td colSpan="6" style={{ padding: "2rem" }}>
                <Center w="100%">
                  <Spinner />
                </Center>
              </td>
            </Tr>
          ) : users && users.users.length === 0 ? (
            <Tr>
              <Td colSpan="10" textAlign="center">
                Pas de réponse
              </Td>
            </Tr>
          ) : (
            users.users.map((user) => (
              <Tr key={user._id}>
                <Td>
                  {user.provider === "Facebook" ? (
                    <BsFacebook size="1.5rem" color="#0E67B0" />
                  ) : (
                    <MdOutlineMailOutline size="1.5rem" />
                  )}
                </Td>
                <Td>{user.name}</Td>
                <Td>{user.email}</Td>
                <Td>{user.phone}</Td>
              </Tr>
            ))
          )}
        </tbody>
        <tfoot>
          <Tr rounded="md">
            <Td>Total: {users?.total} </Td>
            <Td colSpan="6" align="center" p="2">
              {users?.num_pages === 0 ? null : (
                <Flex justify="flex-end" borderRadius="5" p="1">
                  <IconButton
                    icon={<ChevronLeftIcon />}
                    fontSize="large"
                    bg="blue.500"
                    _hover={{
                      bg: "blue.400",
                    }}
                    onClick={() =>
                      users.previous &&
                      history.push(
                        `/dashboard/nouveau-utilisateurs/${date}/?page=${users.previous.page}`
                      )
                    }
                  />
                  {Array.apply(null, Array(users?.num_pages)).map((_, i) => (
                    <Button
                      variant="outline"
                      onClick={() =>
                        history.push(
                          `/dashboard/nouveau-utilisateurs/${date}/?page=${
                            i + 1
                          }`
                        )
                      }
                      mx="1"
                      key={i}
                      bg={
                        !query.get("page") && i === 0
                          ? "orange.light"
                          : query.get("page") == i + 1
                          ? "orange.light"
                          : "inherit"
                      }
                      color={
                        !query.get("page") && i === 0
                          ? "white"
                          : query.get("page") == i + 1
                          ? "white"
                          : "inherit"
                      }
                    >
                      {i + 1}
                    </Button>
                  ))}
                  <IconButton
                    icon={<ChevronRightIcon />}
                    bg="blue.500"
                    _hover={{
                      bg: "blue.400",
                    }}
                    fontSize="large"
                    onClick={() =>
                      users.next &&
                      history.push(
                        `/dashboard/nouveau-utilisateurs/${date}/?page=${users.next.page}`
                      )
                    }
                  />
                </Flex>
              )}
            </Td>
          </Tr>
        </tfoot>
      </Table>
    </Sidebar>
  );
}
